/*
    This file is part of jellything (https://codeberg.org/metamuffin/jellything)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2025 metamuffin <metamuffin.org>
*/
import { get_track_kind } from "../mediacaps.ts";
import { VttPlayerTrack } from "./vtt.ts";
import { MSEPlayerTrack } from "./mse.ts";
import { Player } from "../player.ts";
import { SourceTrack } from "../jhls.d.ts";
import { PlayerTrack } from "./mod.ts";

export function create_track(player: Player, node_id: string, track_index: number, metadata: SourceTrack): PlayerTrack | undefined {
    const kind = get_track_kind(metadata.kind)
    if (kind == "subtitles") return new VttPlayerTrack(player, node_id, track_index, metadata)
    else return new MSEPlayerTrack(player, node_id, track_index, metadata)
}
