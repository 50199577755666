/*
    This file is part of jellything (https://codeberg.org/metamuffin/jellything)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2025 metamuffin <metamuffin.org>
*/
/// <reference lib="dom" />
import { TimeRange } from "../jhls.d.ts";
import { OVar } from "../../jshelper/mod.ts";
import { BufferRange } from "../player.ts";

export const TARGET_BUFFER_DURATION = 10
export const MIN_BUFFER_DURATION = 1

export interface AppendRange extends TimeRange { buf: ArrayBuffer, index: number, cb: () => void }

export abstract class PlayerTrack {
    constructor(
        public track_index: number,
    ) { }
    public buffered = new OVar<BufferRange[]>([]);
    public abort = new AbortController()
    async update(_target: number) { }
    public abstract debug(): HTMLElement | OVar<HTMLElement>
}

