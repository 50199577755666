/*
    This file is part of jellything (https://codeberg.org/metamuffin/jellything)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2025 metamuffin <metamuffin.org>
*/
/// <reference lib="dom" />
globalThis.addEventListener("DOMContentLoaded", () => {
    if (!document.body.classList.contains("admin_log")) return
    const log = document.getElementById("log")!

    const warnonly = new URL(globalThis.location.href).searchParams.get("warnonly") == "true"
    const ws = new WebSocket(`/admin/log?stream&warnonly=${warnonly}`)
    ws.onopen = () => console.log("live log connected");
    ws.onclose = () => console.log("live log disconnected");
    ws.onerror = e => console.log(`live log ws error: ${e}`);

    ws.onmessage = msg => {
        const line = JSON.parse(msg.data)

        const td_time = document.createElement("td")
        td_time.classList.add("time")
        td_time.textContent = line.time

        const td_level = document.createElement("td")
        td_level.classList.add("level")
        td_level.innerHTML = line.level_html

        const td_module = document.createElement("td")
        td_module.classList.add("module")
        td_module.textContent = line.module

        const td_message = document.createElement("td")
        td_message.innerHTML = line.message

        const tr = document.createElement("tr");
        tr.classList.add(line.level_class)
        tr.append(td_time, td_level, td_module, td_message)

        log.children[0].children[0].append(tr)
        while (log.children[0].children[0].children.length > 1024)
            log.children[0].children[0].children[0].remove()
    }
})
