/*
    This file is part of jellything (https://codeberg.org/metamuffin/jellything)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2025 metamuffin <metamuffin.org>
*/
globalThis.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll("input.danger").forEach(el => {
        el.addEventListener("click", ev => {
            if (!confirm(`Really ${(el as HTMLInputElement).value}?`)) {
                ev.preventDefault()
            }
        })
    })
})
